import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const LiquidMetal = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });

    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    const geometry = new THREE.PlaneGeometry(5, 3, 32);
    
    const vertexShader = `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `;

    const fragmentShader = `
      uniform float time;
      varying vec2 vUv;

      void main() {
        vec2 position = vUv;
        float color = 0.0;
        color += sin(position.y * 10.0 + time) * 0.2;
        color += sin(position.x * 10.0 + time) * 0.2;
        gl_FragColor = vec4(vec3(color, color, color), 1.0);
      }
    `;

    const uniforms = {
      time: { type: "f", value: 1.0 },
    };

    const material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    });

    const plane = new THREE.Mesh(geometry, material);
    scene.add(plane);

    camera.position.z = 5;

    const animate = () => {
      requestAnimationFrame(animate);
      uniforms.time.value += 0.05;
      renderer.render(scene, camera);
    };

    animate();
  }, []);

  return <div ref={mountRef} />;
};

export default LiquidMetal;