import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import styled, { createGlobalStyle } from "styled-components"
import SEO from "../components/seo";
import Fade from "react-reveal/Fade";
import Card from "../components/Card"
import Section from "../components/Section"
import backgroundVideo from '../videos/liquid-metal.mp4'
import LiquidMetal from "../components/LiquidMetal"


const PageStyle = createGlobalStyle`
  #card-section{
    display: grid;
    grid-gap: 14px;
    grid-template-columns: 1fr 1fr;
    margin-bottom:14px;

    #discover-card {
      grid-column: 1/3;
    }

    #news-card{
      grid-column: 1/3;
    }

    > * {

        @media (max-width:780px)  { 
          grid-column: 1/3;
        
      }
    }
  }
`
;


const Hero = styled.div`
  display: grid;
  grid-template-rows: 50px 2fr 1fr;
  height:100vh;
  overflow: hidden;
  background-color: black;
  color: white;
  
  
  .deadzone{
    height: 50px;
    width: 100%;
    grid-row: 1;
  }

  h3 {

  }

  h1{

  }

  h2{
    transform: translateY(10px);
  }

  a{
    transform: translateY(30px);
  }

  .hero-image{
    grid-row: 3;
  }

`

const HeroInner = styled.div`
  grid-row:2;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
`


const Intro = styled.div`
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 0 100px 0;

  h1{
    /* padding: 100px 0 0 0; */
    /* order: 1; */
  }

  h3{
    padding: 50px 0 50px 0;
    /* order: 2; */

    @media (max-width:980px)  { 
    /* order: 3; */
  }

  }

  .intro-image{
    margin-top: 50px;
    /* order: 3; */

    @media (max-width:980px)  { 
    /* order: 2; */
  }

  }

  .intro-button{
    /* order: 3; */
  }

`


const Main = styled.div`
  display: grid;
  grid-gap: 14px;
  width: 100%;
  grid-template-columns: 1fr 1fr;

  #discover-card {
    grid-column: 1/3;
  }

  #news-card{
    grid-column: 1/3;
  }

  > * {

      @media (max-width:780px)  { 
        grid-column: 1/3;
      
    }
  }
  
`

const Test = styled.div`
background-color: black;
height: 400px;
`

const Row = styled.div`
display: flex;
gap: 14px;
`
const Col = styled.div`
display: flex;
gap: 14px;
`

const HeroVideo = styled.div`
  z-index: -1;
  overflow: hidden;

  video{
    width: 100%;
    height: auto;
    margin-top: -8%;
  }

  @media (max-width: 680px) {
    video{
    width: 200%;
    height: auto;
    margin-left:-45%;
  }
  }
`
const HomePage = () => {

  return (
    <div>
    <Layout />
    <PageStyle />
    <SEO title="Product Design Company"/>
      <Hero>
        <div className="deadzone" />
        <HeroInner>
        <Fade duration={1000} delay={250}>
        <h3 className="md font-sm center">Design, Engineering, Prototyping &amp; Production </h3>
        <h1 className="lg font-xl center">Transforming ideas into products.</h1>
        <h2 className="sm font-md center">Learn more about how we work inventors and startups to develop new products.</h2>  
        </Fade>
        <Link className="button solid-light center" to="/contact">Get Started</Link>
        </HeroInner>
        <StaticImage className="sm hero-image" src="../images/placeholder.jpg" />
      </Hero>
      <Intro>

        <LiquidMetal />

      <HeroVideo>
        <video autoPlay loop muted id="video">
          <source src={backgroundVideo} type='video/mp4' />
        </video>
        </HeroVideo>
       
      {/* <Fade bottom duration={1000} delay={250}> */}
        <h1 className="md font-xl center">
        Full service product design.<br/> 
        End to end solutions.
        </h1>
        <h3 className="xs font-md center">Our clients partner with us to design, engineer, and develop their product ideas.
         We work with inventors, startups and established brands to develop products that achieve market success through
          balanced design, engineering, and manufacturing processes. 
        </h3>
        {/* </Fade>
        <Fade bottom duration={1000} delay={750}> */}
        <Link className="intro-button button solid-dark center" to="/contact">Get Started</Link>
        {/* </Fade> */}
        <StaticImage className="xl center intro-image" src="../images/placeholder.jpg" />
        </Intro>
         <Section full id="card-section">
            <Card 
            id="discover-card"
            title="Discover"
            tagline="tagline here"
            backgroundImage="https://via.placeholder.com/150"
            buttonText="Learn More"
            to="/scroll" 
            >
              </Card>     
          <Card
          id="design-card"
          title="Design"
          tagline="tagline here"
          backgroundImage="https://via.placeholder.com/150"
          buttonText="Learn More"
          buttonStyle="outline-dark"
          to="/design"   
          >
            </Card>
          <Card
          id="engineering-card"
          title="Engineering"
          tagline="tagline here"
          backgroundImage="https://via.placeholder.com/150"
          buttonText="Learn More"
          to="/scroll"   
          >
            </Card>
          <Card
          id="prototyping-card"
          title="Prototyping"
          tagline="tagline here"
          backgroundImage="https://via.placeholder.com/150"
          buttonText="Learn More"
          to="/scroll"   
          >
            </Card>
            <Card
          id="production-card"
          title="Production"
          tagline="tagline here"
          backgroundImage="https://via.placeholder.com/150"
          buttonText="Learn More"
          to="/scroll"   
          >
            </Card>
            <Card
          id="news-card"
          title="News"
          tagline="tagline here"
          backgroundImage="https://via.placeholder.com/150"
          buttonText="Learn More"
          to="/scroll"   
          >
            </Card>
        </Section> 
    </div>
  )
}

export default HomePage

// export default function Home() {
//   return <div>
//     <h1>Hello World</h1>
//     <StaticImage src="../images/placeholder.jpg"
//     width={600} 
//     />
//   </div>
// }