import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const CardWrapper = styled.div`
  width: ${props => props.width || '100%'};
  height: 100%;
  background-color: black;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const InnerCard = styled.div`
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 20px;
  color: white;
`;

const Title = styled.h1`
  font-size: 32px;
  margin: 0;
`;

const Tagline = styled.h2`
  font-size: 20px;
  margin: 0;
`;

const Button = styled(Link)`
`;

const Card = ({ id, className, title, tagline, buttonText, to, backgroundImage, width }) => {
  return (
    <CardWrapper className={className} id={id} backgroundImage={backgroundImage} width={width}>
      <InnerCard>
        <div>
          <Title>{title}</Title>
          <Tagline>{tagline}</Tagline>
        </div>
        <Button className="button outline-light" to={to}>{buttonText}</Button>
      </InnerCard>
    </CardWrapper>
  );
};

export default Card;